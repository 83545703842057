import React, { useEffect, useState, useRef, FC } from "react";
import { gql, useMutation, useQuery, useSubscription } from "@apollo/client";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { GetBaysQuery, GetSiteQuery } from "../../gql/graphql";

const BAYS = gql`
  query GetBays($siteId: UUID!) {
    bays(siteId: $siteId) {
      items {
        id
        name
        areaId
        number
        isOccupied
        isOverstay
        senseIqId
      }
    }
  }
`;

const TRIGGER_REFRESH = gql`
  mutation TriggerRefresh($siteId: UUID!) {
    triggerRefresh(siteId: $siteId) {
      id
    }
  }
`;

const SUBSCRIBE = gql`
  subscription BayUpdated($siteId: UUID!) {
    bayUpdated(siteId: $siteId) {
      id
      name
      areaId
      number
      isOccupied
      isOverstay
      senseIqId
    }
  }
`;

type SiteType = NonNullable<
  NonNullable<GetSiteQuery["sites"]>["items"]
>[number];

export const Status: FC<{ site: SiteType }> = ({ site }) => {
  const [bays, setBays] = useState<NonNullable<GetBaysQuery["bays"]>["items"]>(
    []
  );

  const navigate = useNavigate();

  // need this for subscription?
  const latestBays = useRef<typeof bays>(bays);
  latestBays.current = bays;

  const { data: baysData } = useQuery<GetBaysQuery>(BAYS, {
    variables: { siteId: site?.id },
    fetchPolicy: "no-cache",
  });
  const { data: subData } = useSubscription(SUBSCRIBE, {
    variables: { siteId: site?.id },
  });
  const [triggerRefresh] = useMutation(TRIGGER_REFRESH, {
    variables: { siteId: site?.id },
  });

  useEffect(() => {
    if (baysData?.bays?.items) {
      setBays(baysData.bays.items);
    }
  }, [baysData]);

  useEffect(() => {
    if (subData && latestBays.current) {
      var latest = [...latestBays.current];
      latest[latest.findIndex((x) => x?.id === subData.bayUpdated.id)] =
        subData.bayUpdated;
      setBays(latest);
    }
  }, [subData]);

  const updateSite = () => {
    triggerRefresh({ variables: { siteId: site?.id } }).then((x) => {
      if (x.data.triggerRefresh) {
        toast(`Get Site Configuration command sent`, {
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });
        navigate("/sites");
      }
    });
  };

  return (
    <>
      <div className="text-end">
        <button className="btn btn-primary" onClick={() => updateSite()}>
          Get Site Configuration
        </button>
      </div>

      {site?.areas?.map((area) => {
        return (
          <div key={area?.id} className="card mt-3">
            <div className="card-header">
              <strong>{area?.name}</strong> - Capacity: {area?.capacity},
              Occupied:{" "}
              {
                bays?.filter((b) => b?.areaId === area?.id && b?.isOccupied)
                  .length
              }
              , Free:{" "}
              {
                bays?.filter((b) => b?.areaId === area?.id && !b?.isOccupied)
                  .length
              }
            </div>
            <div className="card-body">
              {bays
                ?.filter((b) => b?.areaId === area?.id)
                .map((bay, index) => {
                  return (
                    <div
                      key={bay?.id}
                      className={
                        "badge me-1 mb-3 " +
                        (bay?.isOccupied ? "bg-danger" : "bg-success")
                      }
                    >
                      {bay?.name}
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </>
  );
};
