import React, { FC, useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Controller, FieldValues, SubmitHandler, UseFormRegister, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message"
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { TextInput } from "../../components/TextInput";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import {
  ProductDto as ProductDtoType,
  ProductTime,
  GetProductsAndSitesQuery,
  ProductDtoFilterInput,
} from "../../gql/graphql";
import Multiselect from "multiselect-react-dropdown";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

const GET = gql`
  query GetProductsAndSites($siteId: UUID!, $where: ProductDTOFilterInput) {
    products(siteId: $siteId, where: $where) {
      items {
        id
        name
        description
        siteId
        pricePerHour
        maxPricePerDay
        isActive
        productTimesJson
        mustArriveBefore
        mustDepartAfter
        mustArriveAfter
        mustDepartBefore
      }
    }
    sites(where: { id: { eq: $siteId } }) {
      items {
        id
        name
      }
    }
  }
`;

const UPDATE = gql`
  mutation UpdateProduct($product: ProductInput!) {
    updateProduct(product: $product) {
      id
      name
      description
      siteId
      pricePerHour
      maxPricePerDay
      isActive
      productTimesJson
      mustArriveBefore
      mustDepartAfter
      mustArriveAfter
      mustDepartBefore
    }
  }
`;

const dayOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const beforeAfterRuleTypes = [
  "No before/after rule selected",
  "Must arrive before and depart after",
  "Must arrive after and depart before"
];

type SiteType = NonNullable<
  NonNullable<GetProductsAndSitesQuery["sites"]>["items"]
>[number];

const hourValidationSchema = z.preprocess((a) => {
    return (!a && a !== '0') ? null : parseInt(z.string().parse(a), 10)
  },
  z.number().min(0).max(24).nullable()
);

const productValidationSchema = z.object({
  name: z.string(),
  description: z.optional(z.string()),
  createdUtc: z.optional(z.string()),
  pricePerHour: z.string(),
  maxPricePerDay: z.string(),
  mustArriveBefore: hourValidationSchema,
  mustDepartAfter:  hourValidationSchema,
  mustArriveAfter: hourValidationSchema,
  mustDepartBefore: hourValidationSchema,
  ruleType: z.string(),
  times: z.array((z.object({
    dayOfWeek: z.number(),
    hour: z.number()
  }))).min(1, "You must select at least one day of the week.").max(7, "You cannot select more than 7 days of the week.")
}).superRefine((data, ctx) => {
  if (data.ruleType === 'Must arrive before and depart after') {
    if (!data.mustArriveBefore && data.mustArriveBefore !== 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Field must be specified ",
        path: ["mustArriveBefore"]
      });
    } else if (!data.mustDepartAfter && data.mustDepartAfter !== 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Field must be specified ",
        path: ["mustDepartAfter"]
      });
    } else if ((data.mustDepartAfter ?? 0) <= (data.mustArriveBefore ?? 0)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Must Depart After must be greater than Must Arrive Before",
        path: ["mustDepartAfter"]
      });
    }
  } else if (data.ruleType === 'Must arrive after and depart before') { 
    if (!data.mustArriveAfter && data.mustArriveAfter !== 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Field must be specified ",
        path: ["mustArriveAfter"]
      });
    } else if (!data.mustDepartBefore && data.mustDepartBefore !== 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Field must be specified ",
        path: ["mustDepartBefore"]
      });
    } else if ((data.mustDepartBefore ?? 0) <= (data.mustArriveAfter ?? 0)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Must Depart Before must be greater than Must Arrive After",
        path: ["mustDepartBefore"]
      });
    }
  }
});

// extracting the type
type ProductValidationSchema = z.infer<typeof productValidationSchema>;

export const Product: FC = () => {
  let { productId, siteId } = useParams();
  const navigate = useNavigate();
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch
  } = useForm<ProductValidationSchema>({
    resolver: zodResolver(productValidationSchema)
  });

  const [site, setSite] = useState<SiteType | null>(null);
  const [product, setProduct] = useState<ProductDtoType | null>(null);

  const [beforeAfterRuleTypeDropDownOpen, setBeforeAfterRuleTypeDropDownOpen] = useState<boolean>(false);
  const toggleBeforeAfterRuleTypeDropDown = () => setBeforeAfterRuleTypeDropDownOpen(!beforeAfterRuleTypeDropDownOpen);

  const ruleType = watch('ruleType');
  const times = watch('times');

  const where: ProductDtoFilterInput = {};
  if (productId !== "new") {
    where.id = { eq: productId };
  }
  const { data } = useQuery<GetProductsAndSitesQuery>(GET, {
    variables: {
      siteId: siteId,
      where,
    },
  });
  const [updateProduct, { loading: updating }] = useMutation(UPDATE);

  /**
   * Load Product and Site data
   */
  useEffect(() => {
    if (productId === "new" && siteId) {
      setProduct({
        id: "00000000-0000-0000-0000-000000000000",
        name: "",
        description: "",
        createdUtc: null,
        isActive: true,
        siteId,
        pricePerHour: null,
        maxPricePerDay: null,
        mustArriveBefore: null,
        mustDepartAfter: null,
        mustArriveAfter: null,
        mustDepartBefore: null,
      });
    } else if (data?.products?.items?.length === 1) {
      const product = data.products.items[0];
      setProduct(product);
      setValue('times',
        product?.productTimesJson
          ? (JSON.parse(product.productTimesJson) as ProductTime[])
              .filter((pt) => pt.hour === 1) // Only want one result per day for the control (note: On save, we'll mark/save the 24hr period)
          : []
      );
    }

    if (data?.sites?.items?.length === 1) {
      setSite(data.sites.items[0]);
    }
  }, [data, productId, setValue, siteId]);

  /**
   * Initial determination of rule type dropdown
   */
  useEffect(() => {
    if (!product?.mustArriveAfter && !product?.mustDepartBefore && !product?.mustArriveBefore && !product?.mustDepartAfter) {
      setValue('ruleType', 'No before/after rule selected');
    } else if (product?.mustArriveBefore && product?.mustDepartAfter) {
      setValue('ruleType', 'Must arrive before and depart after');
    } else if (product?.mustArriveAfter && product?.mustDepartBefore) {
      setValue('ruleType', 'Must arrive after and depart before');
    }
  }, [product, setValue]);

  const onSubmit: SubmitHandler<ProductValidationSchema> = (data) => {  
    // Remove the times and ruleType input from the data object as it's not available in the ProductInput type
    const updatedProductData = {...data} as any; // Needs to be Any to delete optional properties
    delete updatedProductData.times;
    delete updatedProductData.ruleType;

    // Create an array of 24 hours for each day/time we have to save (note: On load, we only display/provide the first hour of each day)
    const saveTwentFourHoursOfTimes = times.flatMap((pt) => { 
      return [...Array(24).keys()].map((hour) => { 
        return { dayOfWeek: pt.dayOfWeek, hour: hour }; 
      }) 
    });

    if (productId === "new") {
      updateProduct({
        variables: {
          product: {
            siteId: siteId,
            id: product?.id,
            isActive: product?.isActive,
            productTimesJson: JSON.stringify(saveTwentFourHoursOfTimes),
            ...updatedProductData,
            pricePerHour: data.pricePerHour
              ? parseFloat(data.pricePerHour)
              : null,
            maxPricePerDay: data.maxPricePerDay
              ? parseFloat(data.maxPricePerDay)
              : null
          },
        },
      }).then((result) => {
        toast.success("Product added");
        navigate(`/sites/${siteId}/products`);
      });
    } else {
      updateProduct({
        variables: {
          product: {
            siteId: siteId,
            id: product?.id,
            isActive: product?.isActive,
            productTimesJson: JSON.stringify(saveTwentFourHoursOfTimes),
            ...updatedProductData,
            pricePerHour: data.pricePerHour
              ? parseFloat(data.pricePerHour)
              : null,
            maxPricePerDay: data.maxPricePerDay
              ? parseFloat(data.maxPricePerDay)
              : null
          },
        },
      }).then((result) => {
        toast.success("Product updated");
        navigate(`/sites/${siteId}/products`);
      });
    }
  };

  console.log("Validation Errors", errors);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2">
        <h1 className="h2">{site && site.name} </h1>
      </div>

      {product && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={
            "needs-validation" +
            (Object.keys(errors).length > 0 ? " was-validated" : "")
          }
        >
          <div className="row">
            <div className="col-md">
              <TextInput
                name="name"
                label="Name"
                defaultValue={product.name ?? ""}
                placeholder="Name"
                register={register as any as UseFormRegister<FieldValues>}
                config={{ required: true }}
                errors={errors}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <TextInput
                name="description"
                label="Description"
                defaultValue={product.description ?? ""}
                placeholder="Description"
                register={register as any as UseFormRegister<FieldValues>}
                config={{ required: false }}
                errors={errors}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md">
              <label htmlFor="pricePerHour" className="pricePerHourLabel">
                Price per Hour
              </label>
              <input
                type="number"
                min={0}
                id="pricePerHour"
                className="form-control"
                aria-describedby="pricePerHourLabel"
                defaultValue={product.pricePerHour}
                {...register("pricePerHour")}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md">
              <label htmlFor="maxPricePerDay" className="maxPricePerDayLabel">
                Max Price per Day
              </label>
              <input
                type="number"
                min={0}
                id="maxPricePerDay"
                className="form-control"
                aria-describedby="maxPricePerDayLabel"
                defaultValue={product.maxPricePerDay}
                {...register("maxPricePerDay")}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md">
              <label htmlFor="beforeAfterRuleType" className="beforeAfterRuleType">
                Before or After Rule type
              </label>
              <Controller
                name="ruleType"
                control={control}
                render={() => {
                  return (
                    <Dropdown isOpen={beforeAfterRuleTypeDropDownOpen} toggle={toggleBeforeAfterRuleTypeDropDown} setActiveFromChild={true}>
                      <DropdownToggle caret>{ruleType}</DropdownToggle>
                      <DropdownMenu>
                        {beforeAfterRuleTypes.map((ruleType, index) => {
                          return <DropdownItem key={index} onClick={() => {
                            setValue('mustArriveBefore', null);
                            setValue('mustDepartAfter', null);
                            setValue('mustArriveAfter', null);
                            setValue('mustDepartBefore', null);
                            setValue('ruleType', ruleType)
                          }}>{ruleType}</DropdownItem>;
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  )
                }}
              />
            </div>
          </div>

          {ruleType === "Must arrive before and depart after" && (
            <div className="row mb-3">
              <div className="col-md">
                <label
                  htmlFor="mustArriveBefore"
                  className="mustArriveBeforeLabel"
                >
                  Must Arrive Before
                </label>
                <input
                  type="number"
                  id="mustArriveBefore"
                  className="form-control"
                  aria-describedby="mustArriveBeforeLabel"
                  defaultValue={product.mustArriveBefore ?? ""}
                  {...register("mustArriveBefore")}
                />
                <ErrorMessage
                  errors={errors}
                  name="mustArriveBefore"
                  render={({ message }) => <p>{message}</p>}
                />
              </div>
              <div className="col-md">
                <label htmlFor="mustDepartAfter" className="mustDepartAfterLabel">
                  Must Depart After
                </label>
                <input
                  type="number"
                  id="mustDepartAfter"
                  className="form-control"
                  aria-describedby="mustDepartAfterLabel"
                  defaultValue={product.mustDepartAfter ?? ""}
                  {...register("mustDepartAfter")}
                />
                <ErrorMessage
                  errors={errors}
                  name="mustDepartAfter"
                  render={({ message }) => <p>{message}</p>}
                />
              </div>
            </div>
          )}

          {ruleType === "Must arrive after and depart before" && (
            <div className="row mb-3">
              <div className="col-md">
                <label
                  htmlFor="mustArriveAfter"
                  className="mustArriveAfterLabel"
                >
                  Must Arrive After
                </label>
                <input
                  type="number"
                  id="mustArriveAfter"
                  className="form-control"
                  aria-describedby="mustArriveAfterLabel"
                  defaultValue={product.mustArriveAfter ?? ""}
                  {...register("mustArriveAfter")}
                />
                <ErrorMessage
                  errors={errors}
                  name="mustArriveAfter"
                  render={({ message }) => <p>{message}</p>}
                />
              </div>
              <div className="col-md">
                <label htmlFor="mustDepartBefore" className="mustDepartBeforeLabel">
                  Must Depart Before
                </label>
                <input
                  type="number"
                  id="mustDepartBefore"
                  className="form-control"
                  aria-describedby="mustDepartBeforeLabel"
                  defaultValue={product.mustDepartBefore ?? ""}
                  {...register("mustDepartBefore")}
                />
                <ErrorMessage
                  errors={errors}
                  name="mustDepartBefore"
                  render={({ message }) => <p>{message}</p>}
                />
              </div>
            </div>
          )}

          <div className="row mb-3">
            <div className="col-md">
              <label htmlFor="dayOfWeekDropdown" className="dayOfWeekDropdown">
                Day of Week
              </label>
              <Controller
                name="times"
                control={control}
                render={() => {
                  return (
                  <Multiselect
                    id="dayOfWeekDropdown"
                    displayValue="key"
                    options={dayOfWeek.map((dow, index) => { return { cat: index, key: dow }; })}
                    selectedValues={times?.map((pt) => { return { cat: pt?.dayOfWeek, key: dayOfWeek[pt?.dayOfWeek] }; }) ?? []}
                    onSelect={(selectedList) => {
                      setValue('times', selectedList.map((item: any) => { return { dayOfWeek: parseInt(item.cat), hour: 1 }; }));
                    }}
                    onRemove={(selectedList) => {
                      setValue('times', selectedList.map((item: any) => { return { dayOfWeek: parseInt(item.cat), hour: 1 }; }));
                    }}
                    showCheckbox
                  />
                )
              }}
            />
            <ErrorMessage
              errors={errors}
              name="times"
              render={({ message }) => <p>{message}</p>}
            />
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <input
                type="checkbox"
                className="btn-check"
                id="btn-check-outlined"
                autoComplete="off"
                onClick={() =>
                  setProduct({ ...product, isActive: !product.isActive })
                }
              />
              <label
                className={
                  product.isActive ? "btn btn-success" : "btn btn-danger"
                }
                htmlFor="btn-check-outlined"
              >
                {product.isActive ? "Active" : "Inactive"}
              </label>
            </div>
          </div>

          <div className="mt-3 mb-3">
            <input
              disabled={updating}
              className="btn btn-primary"
              type="submit"
              value="Save"
            />{" "}
            <Link to={`/sites/${siteId}/products`} className="btn btn-link">
              Cancel
            </Link>
          </div>
        </form>
      )}
    </>
  );
};
