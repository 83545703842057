import React, { useContext, useState, useEffect, FC } from "react";
import firebaseApp from "./firebase";
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  sendEmailVerification,
  User,
} from "firebase/auth";
import { apiUrl } from "../config";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";

export type Profile = {
  token: string | null;
  roles: string[];
  emailVerified: boolean;
  user?: User;
};

export const firebaseAuth = getAuth(firebaseApp);

const AuthContext = React.createContext<{
  currentUser: User | null;
  loading: boolean;
  logout: () => Promise<void>;
  token: string | null;
  roles: string[];
  sendEmailVerification: () => Promise<boolean>;
  emailVerified: boolean;
  impersonate: (token: string) => void;
  impersonating: boolean;
  setApolloClient: React.Dispatch<React.SetStateAction<ApolloClient<NormalizedCacheObject> | undefined>>;
}>({
  currentUser: null,
  loading: true,
  logout: async () => {},
  token: null,
  roles: [],
  sendEmailVerification: async () => false,
  emailVerified: false,
  impersonate: (token: string) => {},
  impersonating: false,
  setApolloClient: () => {},
});

export const useAuth = () => useContext(AuthContext);
export const useUser = () => {
  const { currentUser } = useContext(AuthContext);
  if (currentUser == null) {
    throw new Error("Current User is null");
  }
  return currentUser;
};

export const getStoredProfile = (key: string) => {
  const storedJsonStr = localStorage.getItem(key);
  try {
    if (storedJsonStr) {
      const profile = JSON.parse(storedJsonStr) as Profile;

      if (profile.token) {
        const decoded = parseJwt(profile.token);

        const now = new Date().getTime() / 1000;
        if (decoded.exp < now) {
          localStorage.removeItem(key);
          return null;
        }
      }

      return profile;
    }
  } finally {
  }
};

function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const AuthProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const previous = getStoredProfile("user") ?? {
    token: null,
    roles: [],
    emailVerified: false,
  };
  const impersonation = getStoredProfile("impersonate") ?? null;

  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject> | undefined>(undefined);

  const [currentUser, setCurrentUser] = useState<User | null>(
    impersonation ? impersonation.user ?? null : null
  );
  const [token, setToken] = useState(
    impersonation ? impersonation.token : previous.token
  );
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState(
    impersonation ? impersonation.roles : previous.roles
  );
  const [emailVerified, setEmailVerified] = useState(
    impersonation ? impersonation.emailVerified : previous.emailVerified
  );
  const [impersonating, setImpersonating] = useState(impersonation !== null);

  async function logout() {
    if (impersonating) {
      localStorage.removeItem("impersonate");

      setToken(previous.token);
      setCurrentUser(previous.user ?? null);
      setRoles(previous.roles);
      setEmailVerified(previous.emailVerified);
      setImpersonating(false);

      apolloClient?.clearStore();
    } else {
      setToken(null);
      setCurrentUser(null);
      setRoles([]);
      setEmailVerified(false);

      localStorage.removeItem("user");

      apolloClient?.clearStore();

      await signOut(firebaseAuth);
    }
  }

  async function sendEmailVerificationInner() {
    if (currentUser) {
      await sendEmailVerification(currentUser, {
        url: "https://" + window.location.host + "/",
      });
    }
    return true;
  }

  function authenticate(user: User, idToken: unknown) {
    const metadata = user.toJSON() as { [key: string]: unknown };
    metadata.token = idToken;

    fetch("https://" + apiUrl + "/auth", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(metadata),
    })
      .then((response) => response.json())
      .then((data) => {
        setToken(data.token);
        setRoles(data.roles);
        setEmailVerified(data.emailVerified);

        localStorage.setItem(
          "user",
          JSON.stringify({
            token: data.token,
            roles: data.roles,
            emailVerified: data.emailVerified,
            email: user.email,
          })
        );
      });
  }

  function impersonate(id: string) {
    fetch("https://" + apiUrl + "/auth/impersonate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ id: id, token: token }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.user) {
          setCurrentUser(data.user);
          setToken(data.token);
          setRoles(data.roles);
          setEmailVerified(true);
          setImpersonating(true);

          localStorage.setItem(
            "impersonate",
            JSON.stringify({
              token: data.token,
              roles: data.roles,
              emailVerified: true,
              user: data.user,
            })
          );

          apolloClient?.clearStore(); // so impersonated user queries load from db

          window.location.assign("/");
        }
      });
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (impersonating) {
        setLoading(false);
        return;
      }

      if (user !== null) {
        user
          .getIdToken(/*forceRefresh*/ false)
          .then(function (idToken) {
            // now we've got the token. send to backend
            authenticate(user, idToken);
          })
          .catch(function (error) {
            console.error(error);
          });
      }

      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, [impersonating]);

  const value = {
    currentUser,
    loading,
    logout,
    token,
    roles,
    sendEmailVerification: sendEmailVerificationInner,
    emailVerified,
    impersonate,
    impersonating,
    setApolloClient
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
