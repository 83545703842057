import React from "react";
import { useQuery, gql } from "@apollo/client";
import { GetMessagesQuery } from "../../gql/graphql";
import { useParams } from "react-router-dom";
import { ShareparkDateTime } from "../../components/LocalTime";

const GET_MESSAGES = gql`
  query GetMessage($id: UUID!) {
    messages(where: { id: { eq: $id } }) {
      items {
        id
        parkerId
        userId
        recipient
        content
        messageType
        sentTime
        isSent
      }
    }
  }
`;

const Messages = () => {
  let { id, tab } = useParams();

  const { loading, data } = useQuery<GetMessagesQuery>(GET_MESSAGES, {
    variables: {
      id: id,
    },
    fetchPolicy: "network-only",
  });

  const content =
    data?.messages?.items &&
    data?.messages?.items[0]?.content &&
    data?.messages?.items[0]?.content.replace(
      "cid:logo",
      "/images/sharepark-logo.svg"
    );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Message</h1>

        {/* <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2">
            <span className="btn btn-sm btn-outline-secondary">Reply</span>
          </div>
        </div> */}
      </div>

      {data &&
        data.messages &&
        data.messages.items &&
        data.messages.items.length > 0 && (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">
                      {data?.messages?.items[0]?.recipient || "Unknown"}
                    </h5>
                    <p className="card-subtitle text-muted">
                      {(
                        <ShareparkDateTime
                          timestamp={data?.messages?.items[0]?.sentTime}
                        />
                      ) || "Unknown"}
                    </p>
                  </div>
                  <div className="card-body">
                    <p className="card-text">
                      {content ? (
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                      ) : (
                        <p>No content available</p>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default Messages;
