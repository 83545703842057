import React, { FC, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import {
  GetSiteParkersQuery,
  SiteParkerDtoFilterInput,
} from "../../gql/graphql";

export const PARKERS = gql`
  query GetSiteParkers($where: SiteParkerDTOFilterInput) {
    siteParkers(where: $where) {
      items {
        id
        siteId # need to select values in where clause
        parkerId # need to select values in where clause
        parker {
          id
          email
          firstName
          lastName
          mobile
          createdUtc
          isActive
        }
        site {
          id
          name
        }
        area {
          id
          name
        }
        isActive
      }
    }
  }
`;

// const PARKER_HEADERS = [
//   { label: "ID", key: "id" },
//   { label: "Email", key: "email" },
//   { label: "First Name", key: "firstName" },
//   { label: "Last Name", key: "lastName" },
//   { label: "Mobile", key: "mobile" },
//   { label: "Created", key: "createdUtc" },
//   { label: "Active", key: "isActive" },
// ];

export const Permanents: FC<{ siteId?: string; parkerId?: string }> = ({
  siteId,
  parkerId,
}) => {
  const { siteId: sId, parkerId: pId } = useParams();
  if (!siteId) siteId = sId;
  if (!parkerId) parkerId = pId;
  const where: SiteParkerDtoFilterInput = {};
  if (siteId) {
    where.siteId = { eq: siteId };
  }
  if (parkerId) {
    where.parkerId = { eq: parkerId };
  }
  const { data } = useQuery<GetSiteParkersQuery>(PARKERS, {
    variables: {
      where,
    },
    fetchPolicy: "network-only",
  });

  const [showDeleted, setShowDeleted] = useState(false);

  return (
    <div>
      {siteId && (
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
          <h1 className="h2">Permanent Parkers</h1>
          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="btn-group me-2">
              {/* {data?.siteParkers && (
                <CSVLink
                  filename={"parkers.csv"}
                  className="btn btn-sm btn-outline-secondary"
                  headers={PARKER_HEADERS}
                  data={data.siteParkers}
                >
                  Export
                </CSVLink>
              )} */}
              {
                <span
                  className={
                    showDeleted
                      ? "btn btn-sm btn-secondary"
                      : "btn btn-sm btn-outline-secondary"
                  }
                  onClick={() => setShowDeleted(!showDeleted)}
                >
                  Inactive
                </span>
              }
            </div>
            <Link
              className="btn btn-sm btn-primary ms-2"
              to={`/sites/${siteId}/permanent/new`}
            >
              Add New
            </Link>
          </div>
        </div>
      )}

      {data && data.siteParkers && (
        <table className="table">
          <thead>
            <tr>
              {siteId ? (
                <>
                  <th>Email</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Mobile</th>
                </>
              ) : (
                <>
                  <td>Site</td>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {data.siteParkers?.items
              ?.filter((p) => p?.isActive || showDeleted)
              .map((siteParker) => (
                <tr
                  className={siteParker?.isActive ? "" : "table-secondary"}
                  key={siteParker?.id}
                >
                  {siteId
                    ? siteParker?.parker && (
                        <>
                          <td>
                            <Link
                              to={`/sites/${siteId}/permanent/${siteParker?.parker.id}`}
                            >
                              {siteParker?.parker.email}
                            </Link>
                          </td>
                          <td>{siteParker?.parker.firstName}</td>
                          <td>{siteParker?.parker.lastName}</td>
                          <td>{siteParker?.parker.mobile}</td>
                        </>
                      )
                    : siteParker?.site &&
                      siteParker?.parker && (
                        <>
                          <td>
                            <Link
                              to={`/sites/${siteParker?.site.id}/permanent/${siteParker?.parker.id}`}
                            >
                              {siteParker?.site.name}
                            </Link>
                          </td>
                        </>
                      )}
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
