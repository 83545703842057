import React, { FC, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import {
  AccessTimeDtoFilterInput,
  Area,
  GetAccessTimesQuery,
  GetSitesAndSiteParkersQuery,
  UpdateAccessTimesMutation,
} from "../../gql/graphql";

const GET = gql`
  query GetAccessTimes(
    $siteParkerId: UUID, $where: AccessTimeDTOFilterInput) {    
    accessTimes(siteParkerId: $siteParkerId, where: $where) {
      totalCount
      items {
        id
        siteId # need to select values in where clause
        areaId # need to select values in where clause
        siteParkerId # need to select values in where clause
        dayOfWeek
        start
        end
        hasAccess
      }
    }
  }
`;

const UPDATE = gql`
  mutation UpdateAccessTimes($accessTimes: [AccessTimeInput]!) {
    updateAccessTimes(accessTimes: $accessTimes) {
      id
      siteId
      areaId
      dayOfWeek
      start
      end
    }
  }
`;

export const AccessTimes: FC<{
  siteParkerId: string;
  returnUrl: `/sites/${string}/permanent/${string}`;
  site: NonNullable<
    NonNullable<GetSitesAndSiteParkersQuery["sites"]>["items"]
  >[number];
}> = ({ siteParkerId, returnUrl, site }) => {
  const { siteId, areaId } = useParams();
  const navigate = useNavigate();
  // const hours = [...Array(24).keys()];
  const [accessTimes, setAccessTimes] = useState<
    NonNullable<GetAccessTimesQuery["accessTimes"]>["items"]
  >([]);
  const where: AccessTimeDtoFilterInput = {};
  if (siteId) where.siteId = { eq: siteId };
  if (areaId) where.areaId = { eq: areaId };
  if (siteParkerId) where.siteParkerId = { eq: siteParkerId };
  const { data } = useQuery<GetAccessTimesQuery>(GET, {
    variables: { siteParkerId, where },
  });
  const [updateAccessTimes] = useMutation<UpdateAccessTimesMutation>(UPDATE);

  useEffect(() => {
    if (data?.accessTimes?.items) {
      setAccessTimes([...data.accessTimes.items]);
    }
  }, [data]);

  const saveAccessTimes = () => {
    updateAccessTimes({ variables: { accessTimes } }).then((x) => {
      if (x.data?.updateAccessTimes) {
        toast(`access times updated`, {
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });
        navigate(returnUrl);
      }
    });
  };

  const updateAccessTime = (
    id: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!accessTimes) return;
    const newAccessTimes = structuredClone(accessTimes);
    var hour = parseInt(e.target.value);

    let existing = newAccessTimes.find((accessTime) => accessTime?.id === id);
    if (!existing) return;

    if (e.target.name === "start" || e.target.name === "end") {
      existing[e.target.name] = hour;
    }

    if (e.target.name === "start" && (!existing.end || hour > existing.end)) {
      existing.end = hour;
    }

    if (e.target.name === "end" && (!existing.start || hour < existing.start)) {
      existing.start = hour;
    }

    setAccessTimes([...newAccessTimes]);
  };

  return (
    <>
      {site?.areas?.map((area) => {
        return (
          <div className="card mb-3" key={area?.id}>
            <div className="card-body">
              <h5 className="card-title">Area: {area?.name}</h5>

              <table className="table">
                <thead>
                  <tr>
                    <th>Day of week</th>
                    <th>Start Hour</th>
                    <th>End Hour</th>
                    <th style={{ width: 480 }}>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {accessTimes
                    ?.filter((at) => at?.areaId === area?.id)
                    .map((accessTime) => {
                      return (
                        <tr key={accessTime?.id}>
                          <td>{accessTime?.dayOfWeek}</td>
                          <td style={{ minWidth: "80px" }}>
                            <input
                              className="form-control"
                              type="number"
                              value={accessTime?.start ?? ""}
                              min={0}
                              max={24}
                              name="start"
                              onChange={(e) =>
                                updateAccessTime(accessTime?.id, e)
                              }
                            />
                          </td>
                          <td style={{ minWidth: "80px" }}>
                            <input
                              className="form-control"
                              type="number"
                              value={accessTime?.end ?? ""}
                              min={0}
                              max={24}
                              name="end"
                              onChange={(e) =>
                                updateAccessTime(accessTime?.id, e)
                              }
                            />
                          </td>
                          <td>
                            {/* {hours.map(hour => <div key={'hour-' + hour} style={{ display: 'inline-block', marginRight: '1px', width: '19px', height: '38px', backgroundColor: hour >= accessTime?.start && hour < accessTime?.end ? '#696' : '#eee'}}></div>)} */}

                            <div
                              style={{
                                width: "480px",
                                height: "38px",
                                backgroundColor: "#eee",
                              }}
                            >
                              <div
                                style={{
                                  height: "38px",
                                  width:
                                    (accessTime?.end == null ||
                                    accessTime?.start == null
                                      ? 2
                                      : accessTime?.end - accessTime?.start) *
                                      20 +
                                    "px",
                                  backgroundColor: "#696",
                                  marginLeft:
                                    (accessTime?.start ?? 0) * 20 + "px",
                                }}
                              ></div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        );
      })}

      <div className="mt-3 mb-3">
        <button className="btn btn-primary" onClick={saveAccessTimes}>
          Save
        </button>{" "}
        <Link to={returnUrl} className="btn btn-link">
          Cancel
        </Link>
      </div>
    </>
  );
};
