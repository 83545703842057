import React, { useEffect, useMemo, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { TextInput } from "../../components/TextInput";
import { gql, useMutation, useQuery } from "@apollo/client";
import { RESERVATIONS } from "./Reservations";
import { toast } from "react-toastify";
import { Transactions } from "../Transactions/Transactions";
import { Testing } from "../Testing/Testing";
import { ShareparkDateTime } from "../../components/LocalTime";
import { GetReservationQuery } from "../../gql/graphql";
import Moment from "moment";

const GET = gql`
  query GetReservation($id: UUID!) {
    reservations(where: { id: { eq: $id } }) {
      items {
        id
        createdUtc
        uid
        name
        siteId
        site {
          name
        }
        email
        mobile
        plate
        startDate
        startTime
        endDate
        endTime
        transactions {
          id
        }
        isActive
        source
        isPermanentParker
        parker {
          id
          firstName
          lastName
          email
          mobile
        }
        vehicle {
          id
          plate
        }
      }
    }
    sites(where: { isActive: { eq: true } }) {
      items {
        id
        name
        isActive # need to select values in where clause
      }
    }
  }
`;

const CHECK = gql`
  query HaveCapacity(
    $areaId: UUID
    $siteId: UUID!
    $reservationId: UUID
    $start: String!
    $end: String!
  ) {
    haveCapacity(
      areaId: $areaId
      siteId: $siteId
      reservationId: $reservationId
      start: $start
      end: $end
    ) {
      haveCapacity
      error
    }
  }
`;

const UPDATE = gql`
  mutation UpdateReservation($reservation: ReservationDTOInput!) {
    updateReservation(reservation: $reservation) {
      id
      createdUtc
      uid
      name
      siteId
      site {
        name
      }
      email
      mobile
      plate
      startDate
      startTime
      endDate
      endTime
      transactions {
        id
      }
      isActive
      source
      isPermanentParker
      parker {
        id
        firstName
        lastName
        email
        mobile
      }
      vehicle {
        id
        plate
      }
    }
  }
`;

type ReservationType = NonNullable<
  NonNullable<GetReservationQuery["reservations"]>["items"]
>[number];

export const Reservation = () => {
  let { id, tab } = useParams();
  const navigate = useNavigate();

  const bookingTimes = useMemo(() => {
    const start = new Date(2000, 0, 1, 0, 0, 0);
    const end = new Date(2000, 0, 1, 23, 59, 59);

    const times = [];
    let time = start;

    while (time < end) {
      times.push(time.toTimeString().substring(0, 5));
      time = new Date(time.getTime() + 15 * 60000);
    }

    return times;
  }, []);

  const [capacityCheckDisplay, setCapacityCheckDisplay] = useState({
    class: "info",
    message: "Please choose a site and enter times for capacity check",
  });

  const d = new Date();
  const minStart = new Date(d.getTime() - d.getTimezoneOffset() * 60000)
    .toISOString()
    .substring(0, 10);
  const [minEnd, setMinEnd] = useState(
    new Date(d.getTime() - d.getTimezoneOffset() * 60000)
      .toISOString()
      .substring(0, 10)
  );
  const [startMinute, setStartMinute] = useState(0);

  const [reservation, setReservation] = useState<ReservationType | null>(null);
  const { data } = useQuery<GetReservationQuery>(GET, {
    variables: {
      id: id === "new" ? "00000000-0000-0000-0000-000000000000" : id,
    },
  });

  const [updateReservation, { loading: updating }] = useMutation(UPDATE, {
    refetchQueries: id === "new" ? [{ query: RESERVATIONS }] : [],
    awaitRefetchQueries: true,
  }); // [ updateCompany, { data, loading, error }]
  const { data: checkResult } = useQuery(CHECK, {
    variables: {
      areaId: null,
      reservationId: reservation?.id || "00000000-0000-0000-0000-000000000000",
      siteId: reservation?.siteId || "00000000-0000-0000-0000-000000000000",
      start: reservation?.startDate + " " + reservation?.startTime,
      end: reservation?.endDate + " " + reservation?.endTime,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (checkResult?.haveCapacity?.error) {
      setCapacityCheckDisplay({
        class: "danger",
        message: checkResult?.haveCapacity?.error,
      });
    } else {
      switch (checkResult?.haveCapacity?.haveCapacity) {
        case false:
          setCapacityCheckDisplay({ class: "danger", message: "Site is full" });
          break;
        case true:
          setCapacityCheckDisplay({
            class: "success",
            message: "Site has capacity",
          });
          break;
        default:
          setCapacityCheckDisplay({
            class: "info",
            message: "Please choose a site and enter times for capacity check",
          });
          break;
      }
    }
  }, [checkResult]);

  useEffect(() => {
    if (id === "new") {
      const d = Moment();
      const dDate = d.format("YYYY-MM-DD");
      const dTime = d.format("HH:mm:ss");
      setReservation({
        id: "00000000-0000-0000-0000-000000000000",
        startDate: dDate,
        startTime: dTime,
        endDate: dDate,
        endTime: dTime,
        isActive: true,
        isPermanentParker: false,
      });
    }

    if (data?.reservations?.items?.length === 1) {
      setReservation(data.reservations.items[0]);
    }
  }, [data, id]);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const watchDates = watch(["startDate", "endDate", "startTime", "endTime"]);
  const watchSite = watch(["siteId"]);

  useEffect(() => {
    // console.log(reservation);
  }, [reservation]);

  useEffect(() => {
    if (
      watchSite[0] !== "" &&
      watchSite[0] !== reservation?.siteId &&
      reservation
    ) {
      setReservation({ ...reservation, siteId: watchSite[0] });
    }
  }, [watchSite]);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    updateReservation({
      variables: {
        reservation: {
          id: reservation?.id,
          ...data,
          isActive: reservation?.isActive,
          isPermanentParker: reservation?.isPermanentParker || false,
          source: reservation?.source === "public" ? "public" : "admin",
        },
      },
    }).then((x) => {
      if (x.data.updateReservation) {
        toast(
          `Reservation: ${reservation?.startDate} ${reservation?.startTime} saved`,
          { type: toast.TYPE.SUCCESS, autoClose: 3000 }
        );
        navigate("/reservations");
      }
    });
  };

  // console.log(watchDates)

  useEffect(() => {
    if (watchDates[0]) {
      var start = new Date(watchDates[0]);
      var end = new Date(watchDates[1]);

      if (watchDates[0] !== watchDates[1]) {
        setMinEnd(watchDates[0]);
      }

      if (start > end) setValue("endDate", watchDates[0]);

      if (watchDates[0] === watchDates[1]) {
        var startTime = new Date(watchDates[0] + " " + watchDates[2]);
        setStartMinute(startTime.getHours() * 60 + startTime.getMinutes());
      }

      if (
        reservation &&
        (reservation?.startDate !== watchDates[0] ||
          reservation?.endDate !== watchDates[1] ||
          reservation?.startTime !== watchDates[2] ||
          reservation?.endTime !== watchDates[3])
      ) {
        setReservation({
          ...reservation,
          startDate: watchDates[0],
          endDate: watchDates[1],
          startTime: watchDates[2],
          endTime: watchDates[3],
        });
      }
    }
  }, [watchDates]);

  const atLeastOne = () =>
    getValues("email") ||
    getValues("mobile") ||
    getValues("plate") ||
    getValues("name")
      ? true
      : "At least one of the following is required: Purpose, Email, Mobile or Plate";

  return (
    <>
      {reservation && (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
            <h1 className="h2">Reservation</h1>
          </div>

          <ul className="nav nav-tabs mb-3">
            <li className="nav-item">
              <Link
                className={`nav-link ${!tab ? "active" : ""}`}
                to={`/reservations/${id}`}
              >
                Details
              </Link>
            </li>

            {reservation.id !== "00000000-0000-0000-0000-000000000000" && (
              <>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      tab === "transactions" ? "active" : ""
                    }`}
                    to={`/reservations/${id}/transactions`}
                  >
                    Transactions
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className={`nav-link ${tab === "testing" ? "active" : ""}`}
                    to={`/reservations/${id}/testing`}
                  >
                    Testing
                  </Link>
                </li>
              </>
            )}
          </ul>

          {reservation && !tab && (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={
                "needs-validation" +
                (Object.keys(errors).length > 0 ? " was-validated" : "")
              }
              noValidate
            >
              <div>
                <div className="col-md mb-3">
                  <label className="required form-label" htmlFor="siteId">
                    Site
                  </label>
                  <select
                    className="form-select"
                    defaultValue={reservation.siteId ?? undefined}
                    {...register("siteId", { required: true })}
                    required
                  >
                    <option value="">Choose a site</option>
                    {data &&
                      data?.sites?.items?.map((site) => (
                        <option key={site?.id} value={site?.id}>
                          {site?.name}
                        </option>
                      ))}
                  </select>
                  <div className="invalid-feedback">Please choose a site.</div>
                </div>

                {reservation.createdUtc && (
                  <div className="col-md mb-3">
                    <label htmlFor="createdUtc">Created</label>

                    <ShareparkDateTime
                      timestamp={reservation.createdUtc}
                      showAsInput={true}
                    />
                  </div>
                )}

                {reservation.source === "public" ? (
                  <>
                    {reservation.parker && (
                      <div className="col-md">
                        <div className="p-3 border rounded bg-light mb-3">
                          <div>Parker:</div>
                          <div>
                            <Link to={"/parkers/" + reservation.parker.id}>
                              {reservation.parker.firstName}{" "}
                              {reservation.parker.lastName}
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}

                    {reservation.vehicle && (
                      <div className="col-md">
                        <div className="p-3 border rounded bg-light mb-3">
                          <div>Vehicle:</div>
                          <div>
                            <Link to={"/vehicles/" + reservation.vehicle.id}>
                              {reservation.vehicle?.plate}
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="alert alert-info">
                      At least one of the following is required: Purpose, Email,
                      Mobile or Licence Plate
                    </div>

                    <div className="col-md">
                      <TextInput
                        name="name"
                        label="Purpose"
                        defaultValue={reservation.name ?? ""}
                        placeholder="Purpose"
                        register={register}
                        config={{ validate: atLeastOne }}
                        errors={errors}
                      />
                    </div>

                    <div className="col-md">
                      <TextInput
                        name="email"
                        label="Email"
                        defaultValue={reservation.email ?? ""}
                        placeholder="Email Address"
                        register={register}
                        config={{ validate: atLeastOne }}
                        errors={errors}
                      />
                    </div>

                    <div className="col-md">
                      <TextInput
                        name="mobile"
                        label="Mobile"
                        defaultValue={reservation.mobile ?? ""}
                        placeholder="Mobile Number"
                        register={register}
                        config={{ validate: atLeastOne }}
                        errors={errors}
                      />
                    </div>

                    <div className="col-md">
                      <TextInput
                        name="plate"
                        label="Licence Plate"
                        defaultValue={reservation.plate ?? ""}
                        placeholder="Licence Plate"
                        register={register}
                        config={{ validate: atLeastOne }}
                        errors={errors}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="row">
                <div className="col-md">
                  <label
                    htmlFor="startDate"
                    className="col-form-label required"
                  >
                    Start Date
                  </label>
                  <input
                    className="form-control"
                    type="date"
                    defaultValue={reservation.startDate ?? ""}
                    min={minStart}
                    {...register("startDate", { required: true })}
                    required
                  />
                  <div className="invalid-feedback">
                    Please enter a Start Date
                  </div>
                </div>

                {/* <div className="col-md">
                    <label htmlFor="startTime" className="col-md-2 col-form-label required">Start Time</label>
                    <input className="form-control" type="time" name="startTime" defaultValue={reservation.startTime.substring(0,5)} {...register("startTime", { required: true })} step="900" required />
                    <div className="invalid-feedback">Please enter a Start Time</div>
                </div> */}

                <div className="col-md">
                  <label
                    className="col-form-label required"
                    htmlFor="startTime"
                  >
                    Start Time
                  </label>
                  <select
                    className="form-select"
                    defaultValue={reservation.startTime?.substring(0, 5)}
                    {...register("startTime", { required: true })}
                    required
                  >
                    <option value="">Choose a Time</option>
                    {bookingTimes.map((time) => (
                      <option key={"start" + time}>{time}</option>
                    ))}
                  </select>
                  <div className="invalid-feedback">Please choose a time.</div>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <label htmlFor="endDate" className="col-form-label required">
                    End Date
                  </label>
                  <input
                    className="form-control"
                    type="date"
                    defaultValue={reservation.endDate ?? ""}
                    min={minEnd}
                    {...register("endDate", { required: true })}
                    required
                  />
                  <div className="invalid-feedback">
                    Please enter an End Date
                  </div>
                </div>

                {/* <div className="col-md">
                    <label htmlFor="endTime" className="col-md-2 col-form-label required">End Time</label>
                    <input className="form-control" type="time" name="endTime" defaultValue={reservation.endTime.substring(0,5)} {...register("endTime", { required: true })} step="900" required />
                    <div className="invalid-feedback">Please enter an End Time</div>
                </div> */}

                <div className="col-md">
                  <label className="col-form-label required" htmlFor="endTime">
                    End Time
                  </label>
                  <select
                    className="form-select"
                    defaultValue={reservation.endTime?.substring(0, 5)}
                    {...register("endTime", { required: true })}
                    required
                  >
                    <option value="">Choose a Time</option>
                    {bookingTimes.map((time) => {
                      var enabled = true;

                      if (watchDates[0] === watchDates[1]) {
                        var endTime = new Date(watchDates[0] + " " + time);
                        var minutes =
                          endTime.getHours() * 60 + endTime.getMinutes();

                        if (minutes <= startMinute) enabled = false;
                      }

                      return enabled ? (
                        <option key={"end" + time}>{time}</option>
                      ) : null;
                    })}
                  </select>
                  <div className="invalid-feedback">Please choose a time.</div>
                </div>
              </div>

              <div className={"alert mt-3 alert-" + capacityCheckDisplay.class}>
                {capacityCheckDisplay.message}
              </div>

              <div className="row mt-3">
                <div className="col-md">
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="isActive"
                    autoComplete="off"
                    onClick={() =>
                      setReservation({
                        ...reservation,
                        isActive: !reservation.isActive,
                      })
                    }
                  />
                  <label
                    className={
                      reservation.isActive
                        ? "btn btn-success"
                        : "btn btn-danger"
                    }
                    htmlFor="isActive"
                  >
                    {reservation.isActive ? "Active" : "Inactive"}
                  </label>
                </div>
              </div>

              <div className="mt-3 mb-3">
                <input
                  disabled={
                    updating || !checkResult?.haveCapacity?.haveCapacity
                  }
                  className="btn btn-primary"
                  type="submit"
                  value="Save"
                />{" "}
                <Link to={"/reservations"} className="btn btn-link">
                  Cancel
                </Link>
              </div>
            </form>
          )}

          {tab === "transactions" && (
            <Transactions
              reservationId={reservation.id}
              // returnUrl={`/reservations/${reservation.id}/transactions`}
            />
          )}

          {tab === "testing" &&
            reservation.id !== "00000000-0000-0000-0000-000000000000" &&
            reservation.uid && <Testing qrCode={reservation.uid} />}
        </>
      )}
    </>
  );
};
